<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Witnessing Encounter Details</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcWitnessingEncounterDetails }}</h1>
        <div class="d-flex mb-3">
          <iCalendar />
          <p style="margin-left: 20px">
            {{ this.userSelectedEncounter.date.month }} {{ this.userSelectedEncounter.date.day }}
            {{ this.userSelectedEncounter.date.year }}
          </p>
        </div>
        <section class="section-4 bg-block mb-4">
          <div class="body">
            <div class="presentation-details">
              <div class="font-weight-bold">
                <p>
                  <span class="label">{{ translations.tcName }}:</span>
                  <span class="value">{{ this.userSelectedEncounter.wit_name }}</span>
                </p>
              </div>
              <div class="font-weight-bold">
                <p>
                  <span class="label">{{ translations.tcContent }}:</span>
                  <span class="value">{{ this.userSelectedEncounter.wit_content }}</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-5">
          <div class="body">
            <div class="d-flex action-buttons">
              <b-button
                variant="primary"
                @click="handleEditButtonClick"
                class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcEdit }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd">
                {{ translations.tcCancel }}
              </b-button>
              <b-button variant="secondary" @click="handleDeleteButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd">
                {{ translations.tcDelete }}
              </b-button>
            </div>
          </div>
        </section>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'view-witnessing-encounter',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      encounter: {},
    }
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.pageLoad()
    ])
  },
  methods: {
    ...mapActions({
      loadEncounterDetails: 'prayerAndWitnessing/loadEncounterDetails',
      setSelectedEncounter: 'prayerAndWitnessing/setSelectedEncounter',
      addOrUpdateEncounter: 'prayerAndWitnessing/addOrUpdateEncounter',
    }),
    async pageLoad() {
    },
    async handleEditButtonClick() {
      await this.setSelectedEncounter(this.userSelectedEncounter)
      this.$router.push({ path: '/programs/pw/add-witnessing-encounter' })
    },
    async handleCancelButtonClick() {
      this.$router.go(-1)
    },
    async handleDeleteButtonClick() {      
      const encObj = {
        wit_name: this.userSelectedEncounter.wit_name,
        wit_content: this.userSelectedEncounter.wit_content,
        wit_key: this.userSelectedEncounter.wit_key,
        wit_delete_flag: 'true'
      }
      await this.addOrUpdateEncounter(encObj).then(() => {
        this.$router.go(-1)
      })
    },
  },
  computed: {
    ...mapGetters({
      userSelectedEncounterKey: 'prayerAndWitnessing/userSelectedEncounterKey',
      userSelectedEncounter: 'prayerAndWitnessing/userSelectedEncounter',
      prefCulture: 'user/userPreferredCulture'
    }),
  },
  components: {
    iCalendar: iCalendar,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 46px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  columns: 2;
  column-gap: 40px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #979797;
  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }
    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: middle;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    button {
      min-width: 280px;
    }

    &.action-buttons {
      button {
        min-width: inherit;
      }
    }
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>